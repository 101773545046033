import { navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { getUser, makeLogin, forgotPassword } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"

const ForgotPassword = ({ location }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, dispatchAuthentication, runLogin } = useContext(
    AuthenticationContext
  )
  // const [notMatch, setNotMatch] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    setLoading(true)
    // setNotMatch(false)
    await forgotPassword(state.token, data.email).then(async res => {
      let dataL = await res.json()

      if (res.status === 200) {
        // dispatch({ type: "SET_TOKEN", payload: dataL.csrf_token })
        // dispatch({ type: "SET_LOGOUT_TOKEN", payload: dataL.logout_token })
        // console.log(dataL)
        // await getUser(dataL.csrf_token).then(async data => {
        //   // console.log(data)
        //   if (data.isAuthenticated === true) {
        //     sessionStorage.setItem("user", JSON.stringify(data))
        //     toggleNotification({
        //       content: `Welcome ${data.firstname}, login successful.`,
        //     })
        //     dispatchAuthentication({
        //       type: "LOGIN",
        //       payload: data,
        //     })
        //     console.log(data)
        //
        //     runLogin()
        //     navigate("/")
        //   }
        // })
        toggleNotification({content: dataL.message})
      } else {
        toggleNotification({content: dataL.message, error:true})
      }
      // else {
      // dispatchAuthentication({
      //   type: "LOGIN",
      //   payload: data,
      // })
      // console.log(dataL)
      // setNotMatch(dataL.message)
      //  navigate("/login")
      // }
      reset()
    })
    setLoading(false)
  }
  useEffect(() => {
    // console.log(location?.state?.logOut)
    // if (!location?.state?.logOut) {
    if (authentication.currentUserData.isAuthenticated) {
      navigate("/")
      // }
    }

    // console.log(authentication)
    // console.log(authentication)
    // console.log("authentication")
  }, [authentication])
  // if (loading)

  if (authentication.currentUserData.isAuthenticated === false && !loading) {
    return (
      <LayoutMinimal
        title={"Reset Your Password"}
        footer={"©2025 O2O. All rights reserved."}
        metaTitle={"Reset Your Password"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
        <p className="text-reg-16 text-center mb-[20px]">Enter your email address below to reset your password.</p>
          <FloatingInput
            label="Email address"
            hookForm
            name="email"
            // type="email"
            register={register}
            error={errors["email"]}
            required
          />
          <div className="flex justify-center items-center mt-6 space-x-2">
            <Button red widthAuto type="submit">
              Reset Password
            </Button>
            <Button red outline widthAuto onClick={() => navigate('/login')}>
            Cancel
            </Button>
          </div>
        </form>
      </LayoutMinimal>
    )
  }
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
    </div>
  )
}

export default ForgotPassword
